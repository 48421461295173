@import 'scss/global';
@import 'scss/type';
@import 'scss/base';

.App {
    @extend .t-body;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
