@import 'variables';

.t-h1 {
    font-family: $fontFamilyHeading;
    font-size: 18px;
    line-height: (36/32);
    font-weight: $fontWeightBold;
    text-transform: uppercase;

    @include media('>=tablet') {
        font-size: 28px;
    }
}

.t-h2 {
    font-family: $fontFamilyHeading;
    font-size: 24px;
    line-height: (36/32);
    font-weight: $fontWeightBold;
}

.t-h3 {
    font-family: $fontFamilyBody;
    font-weight: $fontWeightBold;
    font-size: 18px;
    line-height: (36/32);
}

.t-body {
    font-family: $fontFamilyBody;
    font-weight: $fontWeightNormal;
    font-size: 12px;
    line-height: (22/14);

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.t-button {
    font-family: $fontFamilyBody;
    font-weight: $fontWeightBold;
    font-size: 14px;
}

.t-title {
    font-family: $fontFamilyHeading;
    font-size: 14px;
    font-weight: $fontWeightBold;
    text-transform: uppercase;
}
