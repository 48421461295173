@import 'variables';

@mixin modal-small() {
    padding: 5px;

    background: $modalBg;

    border-radius: 3px;

    box-shadow: 0 0 5px 0 rgba($black, 0.2);
}

@mixin modal-large() {
    padding: 10px;

    background: $modalBg;

    border-radius: 8px;

    box-shadow: 0 0 20px 0 rgba($black, 0.2);

    @include media('>=tablet') {
        padding: 20px;
    }
}

@mixin unbutton() {
    padding: 0;

    border: 0;
    border-radius: 0;
    outline: 0;

    background: transparent;

    cursor: pointer;
    color: inherit;
    text-align: inherit;
    appearance: none;
}

@mixin delist() {
    margin: 0;
    padding: 0;

    list-style: none;
}
