@import '../../scss/global';

.WelcomeModal {
    @include modal-large;

    position: fixed;
    top: 50%;
    right: 20px;
    left: 20px;
    z-index: 10;

    transform: translateY(-50%);
    text-align: center;

    @include media('>=tablet') {
        right: auto;
        bottom: auto;
        left: 50%;
        max-width: 450px;

        transform: translate(-50%, -50%);
    }
}

.WelcomeModal__title {
    margin: 0;
}

.WelcomeModal__subtitle {
    margin: 10px 0 0 0;
}

.WelcomeModal__copy {
    margin: 10px 50px;

    @include media('>=tablet') {
        margin: 20px 50px;
    }
}

.WelcomeModal__area-list {
    margin: 10px 50px 0 50px;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    list-style: none;
}

.WelcomeModal__area-list__item {
    flex-basis: 33%;
    padding: 5px 0;
}

.WelcomeModal__area-list__label {
    span {
        display: inline-block;
        margin: 0 0 0 5px;
    }
}

.WelcomeModal__area-list__input {

}

.WelcomeModal__secondary-copy {
    margin: 10px 50px;
}

.WelcomeModal__button {
}
