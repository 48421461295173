@import '~normalize.css';
@import '~sanitize.css';

@import 'variables';

html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    background: $offWhite;

    font: $fontWeightNormal #{$baseFontSizeMobile}/$baseLineHeightRatio $fontFamilyBody;
    font-style: normal;
    font-stretch: normal;
    color: $offBlack;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    @include media('>=tablet') {
        font-size: $baseFontSizeTablet;
    }

    @include media('>=desktop') {
        font-size: $baseFontSizeDesktop;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    min-width: 100%;
    min-height: 100%;
    margin: 0;

    background: $offWhite;
}

strong {
    font-weight: $fontWeightBold;
}

a {
    color: inherit;
}
