$black: #000;
$offBlack: #1b1314;
$gray4: #888;
$gray3: #e0e0e0;
$gray2: #e8e8e8;
$gray1: #f0f0f0;
$offWhite: #f8f8f8;
$white: #fff;

$modalBg: rgba($white, 0.8);

$fontFamilyBody: 'Open Sans', sans-serif;
$fontFamilyHeading: 'Open Sans Condensed', sans-serif;

$baseFontSizeMobile: 20px;
$baseFontSizeTablet: 24px;
$baseFontSizeDesktop: 24px;
$baseLineHeightRatio: (30/20);

$fontWeightNormal: 400;
$fontWeightBold: 700;

$breakpoints: (
    mobile: 320px,
    tablet: 700px,
    desktop: 1000px
);
